$publicPath:'';

* {
  box-sizing: border-box;
}
.myTitle {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 24px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 8px;
    width: 4px;
    height: 22px;
    background: #09a666;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.page {
  margin: 24px auto;
  width: 1400px;
  background-color: #fff;
  min-height: calc(100vh - 110px);
  padding: 30px;
  /deep/.demo-form-inline {
    .flex {
      position: relative;
      .unit {
        position: absolute;
        top: 15px;
        right: -8px;
      }
    }
  }
}
